import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const onlineFormCounselling = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      entity: "Online Form Of Counselling (OFOC)",

      application_id: null,
      name: null,
      subject_1: "English",
      subject_2: "Hindi",
      subject_3: "Bengali",
      std_category_1: "",
      std_category_2: "",
      std_category_3: "",

      category_apply_1: "Home Unreserved",

      isholdingFormValid: true,
      UserId: secureLS.get(Global.userId),
      isHome: false,
      payLoad: {},
      isFormAddEditValid: false,
      isLoaderActive: false,
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,

      checkbox4: null,
      checkbox5: null,
      checkbox6: null,
      checkbox7: null,
      checkbox8: null,
      checkbox9: null,
      checkbox10: null,

      candidateValid: false,
      isSubmitted: false,
    };
  },
  created() {
    this.getOFOC(this.UserId);
  },
  computed: {},
  watch: {},
  methods: {
    downloadOfoc() {
      window.location.href =
        "http://103.181.109.178:8080/Download/servlet/DownloadServlet?reportPath=ugb_ofoc_applicationForm.jasper&id=" +
        this.UserId;
    },

    getOFOC(userId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.OFOC.GetOfoc, {
        userId: userId,
      })
        .then((response) => {
          console.log(response);
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
           
            if (response.data.Data[0].CreateDate == "") {
              this.isSubmitted = false;
            } else {
              this.isSubmitted = true;
            }
            this.application_id = response.data.Data[0].ApplicationID;
            this.name = response.data.Data[0].Name;
            this.subject_1 = response.data.Data[0].Subject1;
            this.subject_2 = response.data.Data[0].Subject2;
            this.subject_3 = response.data.Data[0].Subject3;
            this.std_category_1 = response.data.Data[0].StdCategory1;
            this.std_category_2 = response.data.Data[0].StdCategory2;
            this.std_category_3 = response.data.Data[0].StdCategory3;
            if (response.data.Data[0].OfocCategory === "HOME") {
              this.isHome = true;
               
            } else {
              this.isHome = false;
              
            }

            this.candidateValid = true;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          } else {
            Global.showErrorAlert(
              true,
              "error",
              "You are not eligible or your form already submitted"
            );
            this.candidateValid = false;
            this.isSubmitted = true;
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    updateOfoc() {
      if (this.candidateValid == true) {
        if (
          this.checkbox1 === true ||
          this.checkbox2 === true ||
          this.checkbox3 === true
        ) {
          if (this.$refs.holdingFormOfoc.validate()) {
            this.isLoaderActive = true;
            var postData = {
              UserId: this.UserId,
              CategoryApply1: this.category_apply_1,
              CategoryApply2: this.category_apply_1,
              CategoryApply3: this.category_apply_1,
              SubjectApply1: this.checkbox1,
              SubjectApply2: this.checkbox2,
              SubjectApply3: this.checkbox3,
            };

            ApiService.post(ApiEndPoint.OFOC.UpdateOfoc, postData)
              .then((response) => {
                this.isLoaderActive = false;

                if (response.data.status.isSuccess == "true") {
                  Global.showSuccessAlert(
                    true,
                    "success",
                    "OFOC Application submitted successfully"
                  );
                  // this.downloadOfoc();
                  this.isSubmitted = true;
                } else if (response.data.result == "error") {
                  Global.showErrorAlert(true, "error", response.data.message);
                }
              })
              .catch((error) => {
                this.isLoaderActive = false;
                if (
                  error.response.status != 401 ||
                  error.response.status != 403
                ) {
                  Global.showErrorAlert(true, "error", "Something went wrong");
                }
              });
          }
        } else {
          Global.showErrorAlert(
            true,
            "error",
            "Minimum one subject must choose"
          );
        }
      } else {
        Global.showErrorAlert(
          true,
          "error",
          "You are not eligible or your form already submitted"
        );
        this.isholdingFormValid = false;
      }
    },

    // #endregion
  },
};
